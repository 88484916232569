.home-picture {
	background-image: url(/static/media/me.038a57fc.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

