.home-picture {
	background-image: url('./assets/images/me.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}
